import React from "react";
import marketsvg from "assets/img/ups-svgrepo-com.svg"
import { useState } from "react";
import IframeDialog from "./IframeDialog";

const upsData = [
  {
    trackingNumber: "1Z12345E1512345676",
    shipperName: "John Doe",
    destination: "Chennai, Tamil Nadu",
    weight: "5 lbs",
    deliveryDate: "2024-12-15",
    status: "In Transit",
    state: true,
    lat: 13.0827,
    lng: 80.2707
  },
  {
    trackingNumber: "1Z12345E6612345678",
    shipperName: "Jane Smith",
    destination: "Chennai, Tamil Nadu",
    weight: "10 lbs",
    deliveryDate: "2024-12-20",
    status: "Delivered",
    state: false, 
    lat: 13.067439,
    lng: 80.237617
  },
  {
    trackingNumber: "1Z12345E1512388888",
    shipperName: "Suresh Kumar",
    destination: "Tiruvallur, Tamil Nadu",
    weight: "15 lbs",
    deliveryDate: "2024-12-12",
    status: "Delivered",
    state: false, lat: 13.1425,
    lng: 80.0183
  },
  {
    trackingNumber: "1Z12345E1512377777",
    shipperName: "Maya R",
    destination: "Kanchipuram, Tamil Nadu",
    weight: "8 lbs",
    deliveryDate: "2024-12-10",
    status: "In Transit",
    state: false, lat: 12.8342,
    lng: 79.7036
  },
  {
    trackingNumber: "1Z12345E1512399999",
    shipperName: "Ravi Varma",
    destination: "Chennai, Tamil Nadu",
    weight: "12 lbs",
    deliveryDate: "2024-12-18",
    status: "Delivered",
    state: false, lat: 13.084622,
    lng: 80.248357
  },
  {
    trackingNumber: "1Z12345E1512366666",
    shipperName: "Priya D",
    destination: "Vellore, Tamil Nadu",
    weight: "20 lbs",
    deliveryDate: "2024-12-25",
    status: "In Transit",
    state: false, lat: 12.9165,
    lng: 79.1325
  },
  {
    trackingNumber: "1Z12345E1512344444",
    shipperName: "Arun Raj",
    destination: "Chennai, Tamil Nadu",
    weight: "7 lbs",
    deliveryDate: "2024-12-22",
    status: "Delivered",
    state: false, lat: 13.0860,
    lng: 80.2782
  },
  {
    trackingNumber: "1Z12345E1512333333",
    shipperName: "Deepa S",
    destination: "Chennai, Tamil Nadu",
    weight: "9 lbs",
    deliveryDate: "2024-12-19",
    status: "Delivered",
    state: false, lat: 13.0358,
    lng: 80.2445
  },
  {
    trackingNumber: "1Z12345E1512322222",
    shipperName: "Ganesh P",
    destination: "Chennai, Tamil Nadu",
    weight: "4 lbs",
    deliveryDate: "2024-12-16",
    status: "In Transit",
    state: false, lat: 13.0839,
    lng: 80.2707
  },
  {
    trackingNumber: "1Z12345E1512311111",
    shipperName: "Anjali M",
    destination: "Chennai, Tamil Nadu",
    weight: "6 lbs",
    deliveryDate: "2024-12-14",
    status: "Delivered",
    state: true, lat: 13.067439,
    lng: 80.237617
  }
];

function MapExample() {
  // const mapRef = React.useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  React.useEffect(() => {
    const google = window.google;
    // const map = mapRef.current;
    const centerLatLng = new google.maps.LatLng(13.0827, 80.2707); // Chennai Center


    const mapOptions = {
      zoom: 10,
      center: centerLatLng,
      scrollwheel: false,
      mapId: '844c3d38d0391db2',  // Add the Map ID here
      zoomControl: true,
      styles: [], // Add custom styles if needed
    };



    const gMap = new google.maps.Map(document.getElementById("map"), mapOptions);

    upsData.forEach((shipment) => {
      const markerIcon = {
        path: google.maps.SymbolPath.CIRCLE, // Shape of the marker
        fillColor: shipment.state ? 'green' : 'red', // Color based on status
        fillOpacity: 1, // Full color fill
        strokeWeight: 0, // No border
        scale: 10 // Marker size
      };

      // const marker = new google.maps.marker.AdvancedMarkerElement({
      //   position: new google.maps.LatLng(shipment.lat, shipment.lng),
      //   map: gMap,
      //   title: `Tracking #: ${shipment.trackingNumber}`,
      //   icon: markerIcon
      // });

      // Create a PinElement
      const pin = new google.maps.marker.PinElement({
        background: shipment.state ? 'green' : 'red', // Red background
        borderColor: "#FFFFFF", // White border
        glyphColor: "#FFFFFF", // White glyph color
        glyph: "A", // Glyph letter
      });

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: gMap, // Attach the map instance        
        position: { lat: shipment.lat, lng: shipment.lng },
        content: pin.element,
      });



      const contentString = `
        <div class="info-window-content">
          <h3>Tracking #: ${shipment.trackingNumber}</h3>
          <p><strong>Shipper:</strong> ${shipment.shipperName}</p>
          <p><strong>Destination:</strong> ${shipment.destination}</p>
          <p><strong>Status:</strong> ${shipment.status}</p>
          <p><strong>Delivery Date:</strong> ${shipment.deliveryDate}</p>
        </div>
      `;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("click", () => {
        // infowindow.open(gMap, marker);
        const url = `https://demo.thingsboard.io/dashboard/e0ef5360-ab57-11ef-b5a8-ed1aed9a651f?publicId=37c23ea0-4528-11ef-91aa-4b5b857befbc`;
        setIframeUrl(url);
        setShowDialog(true); // Open the dialog
      });
    });
  }, []);

  return (
    <>
      <div className="relative w-full rounded h-600-px">
        <div id="map" className="rounded h-full"  />   
        {/* ref={mapRef} */}
      </div>
      {/* IframeDialog Component */}
      {showDialog && (
        <IframeDialog
          url={iframeUrl}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
}

export default MapExample;
